var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.didLoadData
    ? _c("div", { staticClass: "nextcloud-widget nextcloud-info-wrapper" }, [
        _c("div", [
          _c("div", { staticClass: "logo" }, [
            _c("a", { attrs: { href: _vm.branding.url, target: "_blank" } }, [
              _c("img", { attrs: { src: _vm.branding.logo } }),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.branding.slogan))]),
          ]),
          _c("div", { staticClass: "info" }, [
            _c("p", { staticClass: "brand" }, [
              _vm._v(_vm._s(_vm.branding.name)),
            ]),
            _vm.version.string
              ? _c("p", { staticClass: "version" }, [
                  _c("small", [
                    _vm._v(
                      "Nextcloud " +
                        _vm._s(_vm.tt("version")) +
                        " " +
                        _vm._s(_vm.version.string)
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("p", { staticClass: "username" }, [
              _vm._v(_vm._s(_vm.user.displayName) + " "),
              _c("em", [_vm._v("(" + _vm._s(_vm.user.id) + ")")]),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.lastLoginTooltip(),
                    expression: "lastLoginTooltip()",
                  },
                ],
                staticClass: "login",
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.tt("last-login")))]),
                _vm._v("  "),
                _c("small", [
                  _vm._v(_vm._s(_vm.getTimeAgo(_vm.user.lastLogin))),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.quotaTooltip(),
                  expression: "quotaTooltip()",
                },
              ],
            },
            [
              _c("hr"),
              _c("p", [
                _c("i", { staticClass: "fal fa-disc-drive" }),
                _vm.user.quota.quota > 0
                  ? _c("strong", [_vm._v(_vm._s(_vm.tt("disk-quota")))])
                  : _c("strong", [_vm._v(_vm._s(_vm.tt("disk-space")))]),
                _c("em", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.formatPercent(_vm.user.quota.relative)
                    ),
                  },
                }),
                _c("small", [_vm._v(_vm._s(_vm.tt("of")))]),
                _c("strong", {
                  domProps: {
                    innerHTML: _vm._s(_vm.convertBytes(_vm.user.quota.total)),
                  },
                }),
                _vm.quotaChart.dataLoaded
                  ? _c(
                      "span",
                      [
                        _c("PercentageChart", {
                          attrs: {
                            values: _vm.quotaChart.data,
                            showLegend: false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("hr"),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }